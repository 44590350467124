<template>
    <footer class="footer">
      <p>&copy; 2024 Meu Portfólio. Todos os direitos reservados.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterV'
  }
  </script>
  
  <style scoped>
  .footer {
    background-color: #F0F0F0;
    color: #666666;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
  </style>
  